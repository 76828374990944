<template>
  <div>
    <div style="height: 60px; background-color: #F8F8F8; z-index: 999" class="fixed w-full top-0 left-0">
    </div>
    <vs-tabs class="xl:mt-16 xl:pt-2 event-details">
      <!-- ================================================================================================================== information -->
      <vs-tab style="background-color: transparent;" :label="$t('Information')">
        <div class="overflow-y-auto">
          <vs-card class="vx-col card-event p-5">
            <div>
              <!-- Event Name -->
              <div class="mb-5">
                <label class="text-title">
                  {{ $t('Event Name') }}
                </label>
                <vs-input v-filter-input id="name-event-details" class="w-full mt-1" name="item-name"
                  v-model="dataName" v-validate="'required'" />
                  <label class="text-xs text-danger" v-show="!dataName">{{ $t('Event name is required') }}</label>
              </div>

              <!-- date event -->
              <div class="vx-row mb-5">
                <div class="vx-col w-1/2 mb-2">
                  <label class="text-title">
                    {{ $t('Start Date') }}*
                  </label>
                  <!-- Start Time -->
                  <div v-if="!isFetching" class="input-group relative">
                    <flat-pickr class="date-event" :placeholder="$t('Start Event')"
                      :config="configStartDateTimePicker" v-model="dataStartDate" />
                    <div class="icon-calendar-event">
                      <feather-icon icon="CalendarIcon" :svgClasses="'w-5 h-5 mt-2 mr-2 pointer'" />
                    </div>
                    <label class="text-xs text-danger" v-show="(typeof dataStartDate !== 'string')">{{ $t('Start date is required') }}</label>
                  </div>
                </div>
                <div v-if="!isFetching" class="vx-col w-1/2 mb-2">
                  <label class="text-title">
                    {{ $t('End Date') }}*
                  </label>
                  <!-- End Time -->
                  <div class="input-group relative">
                    <flat-pickr class="date-event" :placeholder="$t('End Event')"
                      :config="configEndDateTimePicker" v-model="dataEndDate" />
                    <div class="icon-calendar-event">
                      <feather-icon icon="CalendarIcon"
                        :svgClasses="'texw-5 h-5 mt-2 mr-2 pointer'" />
                    </div>
                    <label class="text-xs text-danger" v-show="(typeof dataEndDate !== 'string')">{{ $t('End date is required') }}</label>
                  </div>
                </div>
              </div>

              <!-- Event Link -->
              <div class="mb-5 relative">
                <label class="text-title">
                  {{ $t('Event Link') }}
                </label>
                <div class="flex">
                <div @click="focusInput"  style="position: absolute; width: 160px; height: 40px; z-index: 99"></div>
                <vs-input v-filter-input id="name-event-details" :style="widthUrl" class="mt-1 code-input-custom-left" name="item-name"
                  v-model="trimmedURL" readonly/>
                <vs-input ref="custominputurl" @keydown="validateInput" v-filter-input id="name-event-details" class="w-full mt-1 code-input-custom-right" name="item-name"
                  v-model="customUrl" />
                </div>
                <div class="icon-embed-event pointer mt-10" v-tooltip="$t('Copy Event Embed URL')">
                  <feather-icon icon="CodeIcon" :svgClasses="'w-5 h-5 mr-2'"
                    v-clipboard:copy="embedUrl" v-clipboard:success="onCopy"
                    v-clipboard:error="onError" />
                </div>
                <div class="icon-copy-event pointer mt-10" v-tooltip="$t('Copy Event URL')">
                  <feather-icon icon="CopyIcon" :svgClasses="'w-5 h-5 mr-2'"
                    v-clipboard:copy="publicUrl" v-clipboard:success="onCopy"
                    v-clipboard:error="onError" />
                </div>
                <div class="icon-launch-event pointer mt-10">
                  <feather-icon icon="ExternalLinkIcon" :svgClasses="'w-5 h-5 mr-2'"
                    v-tooltip="$t('Launch Event')"
                    @click="goToPublicUrl()" />
                </div>
              </div>

              <!-- allow for participant -->
              <div class="mb-8 mt-8 w-full flex justify-between items-center">
                <div>
                  <div class="text-title">{{ $t('Only for invited Participants') }}</div>
                  <div class="font-normal text-sub-title">
                    {{ $t('Closed preview event, each participant will have specific code sent to their email') }}</div>
                  </div>
                <div>
                  <vs-switch @click.stop="" color="success" v-model="dataIsOnlyParticipant" />
                </div>
              </div>

              <!-- Enabled registration -->
              <div v-if="eventData && eventData.is_enable_registration" class="mb-8 flex justify-between items-center">
                <div>
                  <div class="text-title">{{ $t('Enabled registration') }}</div>
                  <div class="font-normal text-sub-title">
                    {{ $t('Disabled this option to hide registration form for the event') }}</div>
                </div>
                <div>
                  <vs-switch @click.stop="" color="success" v-model="dataIsEnabledRegistrationForm" />
                </div>
              </div>

              <!-- Enabled extended field -->
              <div class="mb-8 flex justify-between items-center">
                <div>
                  <div class="text-title">{{ $t('Enabled extended field') }}</div>
                  <div class="font-normal text-sub-title">
                    {{ $t('Add more field into the partipcipant data (QR, Seat, Notes)') }}</div>
                </div>
                <div>
                  <vs-switch @click.stop="" color="success" v-model="dataIsEnabledExtendedForm" />
                </div>
              </div>

              <!-- Event Code -->
              <div class="vx-row mb-5">
                <div class="vx-col w-1/2 relative">
                  <label class="text-title">
                    {{ $t('Event Code') }}
                  </label>
                  <vs-input v-filter-input id="access-code" class="w-full mt-1" name="access-code" v-model="dataCode"
                    v-validate="'required'" readonly />
                  <div class="icon-copy-event pointer mt-10">
                    <feather-icon icon="CopyIcon" :svgClasses="'w-5 h-5'" v-clipboard:copy="dataCode"
                      v-clipboard:success="onCopy" v-clipboard:error="onError" />
                  </div>
                </div>

                <div class="vx-col w-1/2 relative">
                  <label class="text-title">
                    {{ $t('Moderator Code') }}
                  </label>
                  <vs-input v-filter-input id="access-code" class="w-full mt-1" name="access-code" v-model="dataMCode"
                    v-validate="'required'" readonly />
                  <div class="icon-copy-event pointer mt-10">
                    <feather-icon icon="CopyIcon" :svgClasses="'w-5 h-5'" v-clipboard:copy="dataMCode"
                      v-clipboard:success="onCopy" v-clipboard:error="onError" />
                  </div>
                  </div>
              </div>

              <!-- location and timezone -->
              <div class="vx-row mb-5">
                <div class="vx-col w-1/2">
                  <label class="text-title">
                    {{ $t('Location') }}
                  </label>
                  <vs-input v-filter-input id="location-event-details" class="w-full" name="item-name"
                    v-model="dataLocation" v-validate="'required'" />
                </div>
                <div class="vx-col w-1/2">
                  <label class="text-title">
                    {{ $t('Timezone') }}
                  </label>
                  <vs-input v-filter-input id="location-event-details" class="w-full" readonly name="item-name"
                    v-model="dataTimezone" v-validate="'required'" />
                </div>
              </div>

              <!-- languages and sender name -->
              <div class="vx-row mb-8">
                <div class="vx-col w-1/2">
                  <label class="text-title">
                    {{ $t('Language') }}
                  </label>
                  <vs-select class="w-full" name="item-gender" v-model="dataLanguage">
                    <vs-select-item v-for="item in languages" :key="item.value" :value="item.value"
                      :text="item.text" />
                  </vs-select>
                </div>
                <div class="vx-col w-1/2">
                  <label class="text-title">
                    {{ $t('Sender Name (Email Settings)') }}
                  </label>
                  <vs-input v-filter-input id="sender-name-event-details" class="w-full" name="item-name"
                    v-model="dataSenderName" v-validate="'required'" />
                </div>
              </div>

              <!-- featured image -->
              <div class="mt-4">
                <div class="mb-base">
                  <div class="text-title">{{$t('Event Logo')}}</div>
                  <div>{{$t('Image Should have max 600 pixels in width. For best result')}}</div>
                  <template>
                    <div class="flex">
                      <!-- Image Container -->
                      <div v-if="dataLogo == null">
                        <img src="@/assets/images/file-icons/default-img.jpg" alt="image"
                          class="image-event-overview" />
                      </div>
                      <div v-if="dataLogo != null && dataLogo !== '@/assets/images/logo/blank.png'">
                        <img :src="dataLogo" alt="img" class="image-event-overview" />
                      </div>
                      <div v-if="dataLogo === '@/assets/images/logo/blank.png'">
                        <img src="@/assets/images/logo/blank.png" alt="img" class="image-event-overview" />
                      </div>
                    </div>
                    <!-- Image upload Buttons -->
                    <div class="modify-img flex mt-2">
                      <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg"
                        accept="image/*" data-attrib="image-feature-overview" />
                      <vs-button color="secondary" type="border" class="p-1 px-4"
                        @click="$refs.updateImgInput.click()">
                        <div class="flex items-center pt-1">
                          <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24">
                            <path d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" /></svg>
                          {{$t('Upload File')}}
                        </div>
                      </vs-button>
                      <vs-button v-tooltip="$t('Delete current Logo')" class="ml-2 btn-icon" color="secondary" type="border" icon="delete"
                        @click="dataLogo = null"></vs-button>
                      <vs-button v-tooltip="$t('Hide Logo')" color="secondary" type="border" class="py-1 pl-2 pr-2 ml-2"
                        @click="dataLogo = '@/assets/images/logo/blank.png'">
                        <div class="flex items-center pt-1">
                          <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>square-opacity</title><path d="M19 13V11H21V13H19M21 9V7H19V9H21M21 3H19V5H21V3M17 15H19V13H17V15M21 17V15H19V17H21M13 17V15H15V13H13V11H15V9H13V7H15V5H13V3H3V21H13V19H15V17H13M15 21H17V19H15V21M17 3H15V5H17V3M17 11H19V9H17V11M15 17H17V15H15V17M17 19H19V17H17V19M21 21V19H19V21H21M15 9H17V7H15V9M15 13H17V11H15V13M17 5V7H19V5H17Z" /></svg>
                        </div>
                      </vs-button>
                    </div>
                  </template>
                </div>
              </div>
              <div class="flex justify-end items-center">
                <button :disabled="!isFormValid" class="button-event" :class="{ 'is-loading': isSaving }"
                  @click="updateEvent()">
                  {{ $t('Save Changes') }}
                </button>
              </div>
            </div>
          </vs-card>
        </div>
      </vs-tab>
      <!-- ================================================================================================================== features -->
      <vs-tab :label="$t('Features')">
        <div class="overflow-y-auto" style="">
          <vs-card class="relative vx-col card-event p-5">
            <vs-collapse class="features-event">
              <!-- Audience and QA -->
              <vs-collapse-item id="collapse-audience" open>
                <div slot="header">
                  <div class="flex justify-between items-center">
                    <div class="font-bold">{{ $t('Audience Q & A') }}</div>
                    <div class="mr-10">
                      <vs-switch @click.stop="" color="success" v-model="dataIsAudienceAndQA" />
                    </div>
                  </div>
                </div>
                <!-- Moderation -->
                <div class="ml-8 mb-4 flex justify-between items-center">
                  <div class="text-title">
                    <div>{{ $t('Moderation') }}</div>
                    <div class="text-sub-title">
                      {{ $t('Review question before they go live') }}</div>
                  </div>
                  <div class="mr-10">
                    <vs-switch @click.stop="" color="success" v-model="dataIsModeration" />
                  </div>
                </div>
                <!-- Withdrawal -->
                <div class="ml-8 mb-4 flex justify-between items-center">
                  <div class="text-title">
                    <div>{{ $t('Withdrawal') }}</div>
                    <div class="text-sub-title">
                      {{ $t('Allow participants withdraw their questions') }}</div>
                  </div>
                  <div class="mr-10">
                    <vs-switch @click.stop="" color="success" v-model="dataIsWithdrawal" />
                  </div>
                </div>
                <!-- Replies -->
                <div class="ml-8 mb-4 flex justify-between items-center">
                  <div class="text-title">
                    <div>{{ $t('Replies') }}</div>
                    <div class="text-sub-title">
                      {{ $t('Allow participants to reply on questions') }}</div>
                  </div>
                  <div class="mr-10">
                    <vs-switch @click.stop="" color="success" v-model="dataIsReplies" />
                  </div>
                </div>
                <!-- Maximum Question Length -->
                <div class="ml-8 mb-8 flex justify-between items-center">
                  <div class="text-title">
                    <div class="mb-4">{{ $t('Maximum Question Length') }}</div>
                    <div class="flex">
                      <div v-for="length in maximumQLength" :key="length.value">
                        <div @click="selectMaxLength(length.value)"
                          class="border border-solid d-theme-border-grey-light mr-6 px-8 py-3 rounded"
                          :class="{'bg-primary text-white': dataMaxLength === length.value}">
                          {{ length.value }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Limit Question For Participant -->
                <div class="ml-8 mb-4 flex justify-between items-center">
                  <div class="text-title">
                    <div>{{ $t('Limit Question For Participant') }}</div>
                    <div class="text-sub-title">
                      {{ $t('Set limit on how many question per participant') }}</div>
                  </div>
                  <div class="mr-10 flex items-center">
                    <vs-input v-filter-input
                      style="width:80px"
                      class="mr-2"
                      type="number"
                      name="item-name"
                      v-model="dataLimitQuestionLength"
                      v-validate="'required'"
                      :disabled="!dataIsLimitQuestion"
                    />
                    <vs-switch @click.stop="" color="success" v-model="dataIsLimitQuestion" @change="onLimitChange()" />
                  </div>
                </div>
                <!-- Show Only Recent Question -->
                <div class="ml-8 mb-4 flex justify-between items-center">
                  <div class="text-title">
                    <div>{{ $t('Show Only Recent Question') }}</div>
                    <div class="text-sub-title">
                      {{ $t('Participants only shown recent on the question list') }}</div>
                  </div>
                  <div class="mr-10">
                    <vs-switch @click.stop="" color="success" v-model="dataIsShowOnlyRecent" />
                  </div>
                </div>
                <!-- Close Question -->
                <div class="ml-8 mb-4 flex justify-between items-center">
                  <div class="text-title">
                    <div>{{ $t('Close Question') }}</div>
                    <div class="text-sub-title">
                      {{ $t('Prevent participants from sending new questions') }}</div>
                  </div>
                  <div class="mr-10">
                    <vs-switch @click.stop="" color="success" v-model="dataIsCloseQuestion" />
                  </div>
                </div>
              </vs-collapse-item>
              <!-- Live Polls -->
              <vs-collapse-item class="mb-8" id="collapse-polls" open>
                <div slot="header">
                  <div class="flex justify-between items-center">
                    <div class="font-bold">{{ $t('Live Polls') }}</div>
                    <div class="mr-10">
                      <vs-switch @click.stop="" color="success" v-model="dataIsLivePolls" />
                    </div>
                  </div>
                </div>
                <!-- Vote Counter -->
                <div class="ml-8 mb-4 flex justify-between items-center">
                  <div class="text-title">
                    <div>{{ $t('Vote Counter') }}</div>
                    <div class="text-sub-title">
                      {{ $t('Show how many participants has voted') }}</div>
                  </div>
                  <div class="mr-10">
                    <vs-switch @click.stop="" color="success" v-model="dataIsVoteCounter" />
                  </div>
                </div>
                <!-- Poll Result Detail -->
                <div class="ml-8 mb-4 flex justify-between items-center">
                  <div class="text-title">
                    <div>{{ $t('Poll Result Detail') }}</div>
                    <div class="text-sub-title">
                      {{ $t('Show number of votes instead of percentage') }}</div>
                  </div>
                  <div class="mr-10">
                    <vs-switch @click.stop="" color="success" v-model="dataIsPollResult" />
                  </div>
                </div>
              </vs-collapse-item>
            </vs-collapse>
            <div class="absolute bottom-0 mb-6 mr-10 right-0">
              <button class="button-event" :class="{ 'is-loading': isSaving }"
                @click="updateEvent()">
                {{ $t('Save Changes') }}
              </button>
            </div>
          </vs-card>
        </div>
      </vs-tab>
      <!-- ================================================================================================================== Display -->
      <vs-tab :label="$t('Display')">
        <div class="overflow-y-auto" style="">
          <vs-card class="relative vx-col card-event p-5">
                <!-- Hide Participant Name On Question -->
                <div class="mb-4 flex justify-between items-center">
                  <div class="text-title">
                    <div>{{ $t('Hide Participant Name On Question') }}</div>
                    <div class="text-sub-title">
                      {{ $t('Only show the question') }}</div>
                  </div>
                  <div class="mr-10">
                    <vs-switch @click.stop="" color="success" v-model="dataIsHideParticipantName" />
                  </div>
                </div>
                <!-- Display Latest Question -->
                <div class="mb-4 flex justify-between items-center">
                  <div class="text-title">
                    <div>{{ $t('Display Latest Question') }}</div>
                    <div class="text-sub-title">
                      {{ $t('Pin latest question from participants on presentation view') }}</div>
                  </div>
                  <div class="mr-10">
                    <vs-switch @click.stop="" color="success" v-model="dataIsDisplayLatestQuestion" />
                  </div>
                </div>
                <!-- Dark Font Color -->
                <div class="mb-4 flex justify-between items-center">
                  <div class="text-title">
                    <div>{{ $t('Dark Font Color') }}</div>
                    <div class="text-sub-title">
                      {{ $t('Default color is white - turn in on to have dark color') }}</div>
                  </div>
                  <div class="mr-10">
                    <vs-switch @click.stop="" color="success" v-model="dataIsDarkColor" />
                  </div>
                </div>
                <!-- Display Font -->
                <div class="mb-4 items-center">
                  <div class="text-title">
                    <div>{{ $t('Display Font') }}</div>
                  </div>
                  <vs-select
                    class="selectExample"
                    v-model="dataDisplayFont"
                    >
                    <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in fontList" />
                  </vs-select>
                </div>
                <!-- Display Text Size -->
                <div class="mb-8 flex justify-between items-center">
                  <div class="text-title">
                    <div class="mb-4">{{ $t('Display Text Size') }}</div>
                    <div class="flex">
                      <div v-for="length in fontSizes" :key="length.value">
                        <div @click="selectFontSize(length.value)"
                          class="pointer border border-solid d-theme-border-grey-light mr-6 px-4 py-3 rounded"
                          :class="{'bg-primary text-white': dataFontSize === length.value}">
                          {{ length.text }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- base color -->
                <div class="mb-4 items-center">
                  <div class="text-title">
                    <div>{{ $t('Base Color') }}</div>
                  </div>
                  <div class="flex ">
                    <div class="mb-5 pointer" v-for="(color, index) in baseColors" :key="index">
                      <div @click="changeBaseColor(color)" class="w-10 h-10 rounded mr-2 base-color"
                        :style="{ backgroundColor: color }">
                        <div v-if="dataBaseColor === color">
                          <svg class="w-8 m-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <title>check</title>
                            <path fill="white"
                              d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- event background -->
                <div class="text-title">
                  <div>{{ $t('Display Image Background') }}</div>
                </div>
                <template>
                  <div class="flex">
                    <!-- Image Container -->
                    <div v-if="dataBackground == null">
                      <img src="@/assets/images/file-icons/default-img.jpg" alt="image"
                        class="image-event-overview" />
                    </div>
                    <div v-if="dataBackground != null">
                      <img :src="dataBackground" alt="img" class="image-event-overview" />
                    </div>
                  </div>
                  <!-- Image upload Buttons -->
                  <div class="modify-img flex mt-2">
                    <input type="file" class="hidden" ref="updateImgInputDisplay" @change="updateCurrImgDisplay"
                      accept="image/*" data-attrib="image-feature-overview" />
                    <vs-button color="secondary" type="border" class="p-1 px-4"
                      @click="$refs.updateImgInputDisplay.click()">
                      <div class="flex items-center pt-1">
                        <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24">
                          <path d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" /></svg>
                        {{$t('Upload File')}}
                      </div>
                    </vs-button>
                    <vs-button class="ml-2 btn-icon" color="secondary" type="border" icon="delete"
                      @click="dataBackground = null"></vs-button>
                  </div>
                </template>


                <!-- presentation -->
                <div class="text-title mt-8">
                  <div>{{ $t('Presentation Display') }}</div>
                </div>

                <!-- Question With Sidebar -->
                <div class="relative">

                  <div class="text-title mt-4">
                    <div>{{ $t('Question With Sidebar') }}</div>
                  </div>
                  <vs-input
                    class="mt-0 w-full pr-2"
                    readonly
                    name="item-name"
                    v-model="dataQuestionWithSidebar"
                    v-validate="'required'"
                  />
                  <div class="icon-copy-event pointer pt-1 pr-3">
                    <feather-icon icon="CopyIcon" :svgClasses="'w-5 h-5'" v-clipboard:copy="dataQuestionWithSidebar"
                      v-clipboard:success="onCopy" v-clipboard:error="onError" />
                  </div>
                  <div class="icon-launch-event pointer pt-1 pr-2">
                  <feather-icon icon="ExternalLinkIcon" :svgClasses="'w-5 h-5 mr-2'"
                      @click="goToDisplay('sidebar')" />
                  </div>
                </div>

                <!-- Question Without Sidebar -->
                <div class="relative">
                  <div class="text-title mt-4">
                    <div>{{ $t('Question Without Sidebar') }}</div>
                  </div>
                  <vs-input
                    class="mt-0 w-full pr-2"
                    readonly
                    name="item-name"
                    v-model="dataQuestionWithoutSidebar"
                    v-validate="'required'"
                  />
                  <div class="icon-copy-event pointer pt-1 pr-3">
                    <feather-icon icon="CopyIcon" :svgClasses="'w-5 h-5'" v-clipboard:copy="dataQuestionWithoutSidebar"
                      v-clipboard:success="onCopy" v-clipboard:error="onError" />
                  </div>
                  <div class="icon-launch-event pointer pt-1 pr-2">
                  <feather-icon icon="ExternalLinkIcon" :svgClasses="'w-5 h-5 mr-2'"
                      @click="goToDisplay('quest')" />
                  </div>
                </div>

                <!-- Highlighted Question -->
                <div class="relative">
                  <div class="text-title mt-4">
                    <div>{{ $t('Highlighted Question') }}</div>
                  </div>
                  <vs-input
                    class="mt-0 w-full pr-2"
                    name="item-name"
                    v-model="dataHighlightedPresentQuestion"
                    v-validate="'required'"
                  />
                  <div class="icon-copy-event pointer pt-1 pr-3">
                    <feather-icon icon="CopyIcon" :svgClasses="'w-5 h-5'" v-clipboard:copy="dataHighlightedPresentQuestion"
                      v-clipboard:success="onCopy" v-clipboard:error="onError" />
                  </div>
                  <div class="icon-launch-event pointer pt-1 pr-2">
                  <feather-icon icon="ExternalLinkIcon" :svgClasses="'w-5 h-5 mr-2'"
                      @click="goToDisplay('highlight')" />
                  </div>
                </div>

                <!-- Event QR -->
                <div class="relative">
                  <div class="text-title mt-4">
                    <div>{{ $t('Event QR') }}</div>
                  </div>
                  <vs-input
                    class="mt-0 w-full pr-2"
                    readonly
                    name="item-name"
                    v-model="dataEventQR"
                    v-validate="'required'"
                  />
                  <div class="icon-copy-event pointer pt-1 pr-3">
                    <feather-icon icon="CopyIcon" :svgClasses="'w-5 h-5'" v-clipboard:copy="dataEventQR"
                      v-clipboard:success="onCopy" v-clipboard:error="onError" />
                  </div>
                  <div class="icon-launch-event pointer pt-1 pr-2">
                  <feather-icon icon="ExternalLinkIcon" :svgClasses="'w-5 h-5 mr-2'"
                      @click="goToDisplay('event')" />
                  </div>
                </div>

                <!-- Display for show runner control -->
                <div class="relative">
                  <div class="text-title mt-4">
                    <div>{{ $t('Display for show runner control') }}</div>
                  </div>
                  <vs-input
                    class="mt-0 w-full pr-2"
                    readonly
                    name="item-name"
                    v-model="dataDisplayRunnerControl"
                    v-validate="'required'"
                  />
                  <div class="icon-copy-event pointer pt-1 pr-3">
                    <feather-icon icon="CopyIcon" :svgClasses="'w-5 h-5'" v-clipboard:copy="dataDisplayRunnerControl"
                      v-clipboard:success="onCopy" v-clipboard:error="onError" />
                  </div>
                  <div class="icon-launch-event pointer pt-1 pr-2">
                  <feather-icon icon="ExternalLinkIcon" :svgClasses="'w-5 h-5 mr-2'"
                      @click="goToDisplay('control')" />
                  </div>
                </div>

                <!-- Grafias Integration -->
                <div class="text-title mt-8">
                  <div>{{ $t('Grafias Integration') }}</div>
                </div>

                <!-- Highlighted Question -->
                <div class="relative">
                  <div class="text-title mt-4">
                    <div>{{ $t('Highlighted Question') }}</div>
                  </div>
                    <vs-input
                      class="mt-0 w-full pr-2 mb-2"
                      name="item-name"
                      v-model="dataHighlightedGrafiasQuestion"
                      v-validate="'required'"
                    />
                    <vs-checkbox v-model="dataHighlightGrafiasSwitch" class="mr-2 flex w-1/3">{{ $t('Switch H1 and H2 data')}}</vs-checkbox>
                </div>

            <div class="absolute bottom-0 mb-6 mr-10 right-0">
              <button class="button-event" :class="{ 'is-loading': isSaving }"
                @click="updateEvent()">
                {{ $t('Save Changes') }}
              </button>
            </div>
          </vs-card>
        </div>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import eventsApi from '@/api/event';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import fileApi from '@/api/file';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  getAxiosErrorMessage,
  dateFormat,
} from '../lib/helper';

dayjs.extend(utc);

export default {
  name: 'EventDetails',
  components: {
    flatPickr,
  },
  data() {
    return {
      isFetching: false,
      eventData: null,
      isSidebarActive: false,
      statisticData: null,
      dataLogo: null,
      dataDescription: '',
      dataStartDate: '',
      dataEndDate: '',
      dataLocation: '',
      dataCode: '',
      dataMCode: '',
      dataName: '',
      dataTimezone: '',
      dataLanguage: 'no',
      dataBaseColor: '#0054C7',
      dataIsOnlyParticipant: true,
      dataIsEnabledRegistrationForm: true,
      dataIsEnabledExtendedForm: false,
      dataIsEnabledBoxBackground: false,
      dataIsFullscreen: false,
      dataRemoveFontStyle: false,
      dataIsEnabledCountdown: false,
      isSaving: false,
      customUrl: '',

      configStartDateTimePicker: {
        enableTime: true,
        altInput: true,
        altFormat: 'd F Y - h:i K',
        dateFormat: 'Y-m-d H:i',
        time_24hr: false,
      },
      configEndDateTimePicker: {
        enableTime: true,
        altInput: true,
        altFormat: 'd F Y - h:i K',
        dateFormat: 'Y-m-d H:i',
        time_24hr: false,
      },

      chips: [
        'Vuejs',
        'Node',
        'vuexy',
      ],
      languages: [{
        value: 'no',
        text: 'Norsk',
      },
      {
        value: 'en',
        text: 'English',
      },
      ],
      windowWidth: '',
      baseColors: [
        '#0054C7',
        '#750000',
        '#116400',
        '#5D0279',
        '#79024D',
        '#613400',
        '#005F7C',
        '#181818',
        '#3C6154',
        '#CA0000',
        '#B1B1B1',
      ],

      // features data moderation
      dataIsAudienceAndQA: true,
      dataIsModeration: true,
      dataIsWithdrawal: true,
      dataIsReplies: true,
      dataMaxLength: 160,
      dataFontSize: 28,
      dataIsDisplayPresentSidebar: true,
      dataIsQRCode: true,
      dataIsDisplayLatestQuestion: true,
      dataIsCloseQuestion: true,
      dataIsDisplayQuestionPane: false,
      dataIsShowOnlyRecent: false,
      dataDisplaySort: 'recent',
      maximumQLength: [{
        value: 160,
        text: 160,
      },
      {
        value: 240,
        text: 240,
      },
      {
        value: 300,
        text: 300,
      },
      ],
      fontSizes: [
        {
          text: '100%',
          value: 28,
        },
        {
          text: '150%',
          value: 35,
        },
        {
          text: '200%',
          value: 42,
        },
        {
          text: '250%',
          value: 48,
        },
        {
          text: '300%',
          value: 60,
        },

      ],
      // features data polls
      dataIsLivePolls: true,
      dataIsVoteCounter: true,
      dataIsPollResult: true,

      dataIsLimitQuestion: false,
      dataLimitQuestionLength: 0,
      dataIsHideParticipantName: false,
      dataIsDarkColor: false,
      dataDisplayFont: 'Roboto',
      fontList: [
        { value: 'Arial', text: 'Arial' },
        { value: 'Inter', text: 'Inter' },
        { value: 'Kanit', text: 'Kanit' },
        { value: 'Lato', text: 'Lato' },
        { value: 'Lora', text: 'Lora' },
        { value: 'Merriweather', text: 'Merriweather' },
        { value: 'Montserrat', text: 'Montserrat' },
        { value: 'Noto Serif', text: 'Noto Serif' },
        { value: 'Nunito', text: 'Nunito' },
        { value: 'Roboto', text: 'Roboto' },
        { value: 'Roboto Slab', text: 'Roboto Slab' },
        { value: 'DM Serif', text: 'DM Serif' },
        { value: 'Ubuntu', text: 'Ubuntu' },
        { value: 'Oswald', text: 'Oswald' },
        { value: 'Open Sans', text: 'Open Sans' },
        { value: 'Poppins', text: 'Poppins' },
        { value: 'Raleway', text: 'Raleway' },
        { value: 'Instrument', text: 'Instrument' },
      ],
      dataBackground: null,
      dataHighlightedGrafiasQuestion: '',
      dataHighlightGrafiasSwitch: '',
      dataDisplayRunnerControl: '',
      dataEventQR: '',
      dataHighlightedPresentQuestion: '',
      dataQuestionWithoutSidebar: '',
      dataQuestionWithSidebar: '',
      dataIsEnabledCustomHTML: '',
      dataEventHtml: '',
      dataSenderName: '',
    };
  },
  watch: {
    dataStartDate(newStartDate) {
      this.configEndDateTimePicker.minDate = newStartDate;
      // Check if dataEndDate is greater than newStartDate
      if (newStartDate > this.dataEndDate) {
        // Set dataEndDate to newStartDate
        this.dataEndDate = newStartDate;
      }
      if (typeof this.dataEndDate !== 'string') {
        this.dataEndDate = newStartDate;
      }
    },
    dataIsLivePolls() {
      if (!this.dataIsLivePolls) {
        this.dataIsVoteCounter = false;
        this.dataIsPollResult = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
    slug() {
      return this.$route.params.slug;
    },
    isFormValid() {
      return (
        this.dataName !== ''
				&& typeof this.dataStartDate === 'string'
				&& typeof this.dataEndDate === 'string'
      );
    },
    params() {
      const startTime = dayjs.utc(this.dataStartDate).format('YYYY-MM-DD HH:mm');
      const endTime = dayjs.utc(this.dataEndDate).format('YYYY-MM-DD HH:mm');
      const params = {
        id: this.dataId,
        name: this.dataName,
        start_date: startTime,
        end_date: endTime,
        description: this.dataDescription,
        location: this.dataLocation,
        mcode: this.dataMCode,
        link_code: this.customUrl,
        access_code: this.dataCode,
        timezone: this.dataTimezone,
        custom_html_url: this.dataEventHtml,
        language: this.dataLanguage,
        base_color: this.dataBaseColor,
        logo: this.dataLogo,
        is_only_participants: this.dataIsOnlyParticipant,
      };
      return params;
    },
    publicUrl() {
      const linkCode = this.eventData && this.eventData.link_code ? this.eventData.link_code : '';
      return linkCode;
    },
    trimmedURL() {
      const trimmedUrl = this.publicUrl.substring(0, 'https://koblatil.lomediastudio.no/c/'.length);
      return trimmedUrl;
    },
    widthUrl() {
      if (this.trimmedURL.length === 36) {
        return 'width: 330px';
      }
      return 'width: 228px';
    },
    embedUrl() {
      const baseUrl = this.publicUrl;
      const embedUrl = `${baseUrl}`;

      // Generate the embed code
      const embedCode = `<iframe width="100%" height="1200" src="${embedUrl}" title="${this.dataName}" frameborder="0" allowfullscreen></iframe>`;
      return embedCode;
    },
    linkCodeValue() {
      const linkCode = this.eventData && this.eventData.link_code_value ? this.eventData.link_code_value : '';
      return linkCode;
    },
    sidebarURL() {
      const linkCode = `${process.env.VUE_APP_APP_URL}/c/${this.eventData && this.eventData.slug}/sidebar`;
      return linkCode;
    },
    withoutSidebarURL() {
      const linkCode = `${process.env.VUE_APP_APP_URL}/c/${this.eventData && this.eventData.slug}/quest`;
      return linkCode;
    },
    highlightPresentationURL() {
      const linkCode = `${process.env.VUE_APP_APP_URL}/c/${this.eventData && this.eventData.slug}/highlight`;
      return linkCode;
    },
    eventQRURL() {
      const linkCode = `${process.env.VUE_APP_APP_URL}/c/${this.eventData && this.eventData.slug}/event`;
      return linkCode;
    },
    runnerControlURL() {
      const linkCode = `${process.env.VUE_APP_APP_URL}/c/${this.eventData && this.eventData.slug}/control`;
      return linkCode;
    },
    highlightGrafiasURL() {
      const linkCode = '';
      return linkCode;
    },
    highlighSwitch() {
      const linkCode = '';
      return linkCode;
    },
  },
  mounted() {
    this.isMounted = true;
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  methods: {
    goToDisplay(param) {
      const linkCode = `${process.env.VUE_APP_APP_URL}/c/${this.eventData && this.eventData.slug}/${param}`;
      window.open(linkCode, '_blank');
    },
    onLimitChange() {
      if (this.dataIsLimitQuestion) {
        this.dataLimitQuestionLength = 5;
      } else {
        this.dataLimitQuestionLength = 0;
      }
    },
    changeBaseColor(color) {
      this.dataBaseColor = color;
    },
    selectMaxLength(value) {
      this.dataMaxLength = value;
    },
    selectFontSize(value) {
      this.dataFontSize = value;
    },
    goToPublicUrl() {
      const routeData = this.$router.resolve({
        name: 'EventLive',
        params: {
          eventLinkCode: this.customUrl,
        },
      });
      window.open(routeData.href, '_blank');
    },
    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1);
    },
    validateInput(event) {
      // Regular expression pattern allowing only alphabetic characters, numeric digits, underscores, and dashes
      const regex = /^[a-zA-Z0-9_-]*$/;

      // Check if the input value matches the pattern and the length is less than 50
      if (!regex.test(event.key) && event.key !== 'Backspace' && event.key !== 'Delete') {
        event.preventDefault(); // Prevent typing
      }

      // Prevent typing if the length exceeds 50 characters
      if (this.customUrl.length >= 50 && event.key !== 'Backspace' && event.key !== 'Delete') {
        event.preventDefault();
      }
    },
    fetchDetails(isSlugChanged = false, newSlug) {
      this.$vs.loading();
      this.isFetching = true;
      const callback = (response) => {
        const event = response.data;
        if (response.data === null) {
          this.$router.push('/logout');
          return;
        }
        this.eventData = event;
        this.setData();

        document.title = event.name;
        const eventSettings = JSON.parse(response.data.settings);
        this.setEventSettings(eventSettings, event.link_code_value);
        if (newSlug) {
          this.$router.push({ name: 'EventDetails', params: { slug: newSlug } });
        }
      };
      const errorCallback = () => {
        this.isFetching = false;
        this.$vs.loading.close();
      };
      if (isSlugChanged) {
        eventsApi.getBySlug(newSlug, callback, errorCallback);
      } else {
        eventsApi.getBySlug(this.slug, callback, errorCallback);
      }
    },
    focusInput() {
      this.$refs.custominputurl.$el.querySelector('input').focus();
    },
    updateEvent() {
      this.isSaving = true;
      const notifTitle = this.$t('Event Settings');
      const params = this.params;
      const callback = (response) => {
        // const message = response.message;
        const message = this.$t('Your changes have been saved.');
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.eventData.link_code = this.trimmedURL + this.customUrl;
        if (response.data?.is_change_slug) {
          this.fetchDetails(true, response.data?.slug);
        }
        this.isSaving = false;
      };

      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: notifTitle,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isSaving = false;
      };
      const eventSettings = {
        question_answer: {
          is_enabled: this.dataIsAudienceAndQA,
          is_moderated: this.dataIsModeration,
          is_withdrawal: this.dataIsWithdrawal,
          is_replies: this.dataIsReplies,
          input_max_length: this.dataMaxLength,
          is_order_by_latest: this.dataIsDisplayLatestQuestion,
          is_closed: this.dataIsCloseQuestion,
          display_sort: this.dataDisplaySort,
          is_only_recent: this.dataIsShowOnlyRecent,
          limit: this.dataLimitQuestionLength,
          is_limit: this.dataIsLimitQuestion,
        },
        sidebar: {
          is_display_presentation_sidebar: this.dataIsDisplayPresentSidebar,
          is_qr_code: this.dataIsQRCode,
          is_display_question_pane: this.dataIsDisplayQuestionPane,
          is_registration_only: this.dataIsRegistrationOnly,
          is_registration_enabled: this.dataIsEnabledRegistrationForm,
          is_enable_extended_field: this.dataIsEnabledExtendedForm,
        },
        polls: {
          is_enabled: this.dataIsLivePolls,
          is_vote_counter_visible: this.dataIsVoteCounter,
          is_show_result: this.dataIsPollResult,
        },
        style: {
          is_remove_font_style: this.dataRemoveFontStyle,
          is_dark_color_event: this.dataIsEnabledDarkFont,
          is_enabled_box_bg: this.dataIsEnabledBoxBackground,
          is_fullscreen: this.dataIsFullscreen,
          font_size: this.dataFontSize || 28,
          is_enabled_countdown: this.dataIsEnabledCountdown,
          is_hide_participant_name: this.dataIsHideParticipantName,
          is_dark_color: this.dataIsDarkColor,
          display_font: this.dataDisplayFont,
          background: this.dataBackground,
          background_type: this.dataBackgroundType,
          event_image: this.dataEventImage,
          is_show_countdown: this.dataIsShowCountdown,
          is_show_location: this.dataIsShowLocation,
          is_show_date_time: this.dataIsShowDateTime,
          is_show_tc: this.dataIsShowTC,
          is_show_notes: this.dataIsShowNotes,
          is_show_notes_placeholder: this.dataIsShowNotesPlaceholder,
          notes_placeholder: this.dataNotesPlaceholder,
          tc: this.dataTC,
          note: this.dataNotes,
          is_show_switch_question: this.dataIsShowSwitchQuestionMenu,
        },
        presentation: {
          question_with_sidebar: this.dataQuestionWithSidebar,
          question_without_sidebar: this.dataQuestionWithoutSidebar,
          highlighted_question: this.dataHighlightedPresentQuestion,
          event_qr: this.dataEventQR,
          display_runner_control: this.dataDisplayRunnerControl,
          is_enabled_custom_html: this.dataIsEnabledCustomHTML,
        },
        grafias: {
          highlighted_question: this.dataHighlightedGrafiasQuestion,
          is_switch: this.dataHighlightGrafiasSwitch,
        },
        email: {
          sender_name: this.dataSenderName,
          sender_mail: null,
          footer_name: this.dataSenderName,
        },
      };
      params.settings = JSON.stringify(eventSettings);
      eventsApi.update(this.eventData.id, params, callback, errorCallback);
    },
    dateFormat(date) {
      return dateFormat(date, 'DD. MM. YYYY HH:mm');
    },
    updateCurrImg(input) {
      this.$vs.loading();
      if (input.target.files && input.target.files[0]) {
        this.isUploading = true;
        const file = input.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.dataLogo = e.target.result;
        };
        reader.readAsDataURL(file);

        // Upload Image
        const formData = new FormData();
        formData.append('file', file);

        const callback = (response) => {
          const url = response.data;
          this.dataLogo = url;
          this.isUploading = false;
          this.$vs.loading.close();
        };

        const errorCallback = (e) => {
          const message = getAxiosErrorMessage(e);
          this.$vs.notify({
            title: this.$t('Upload Image'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
          this.isUploading = false;
          this.$vs.loading.close();
        };
        fileApi.upload(formData, callback, errorCallback);
      }
    },
    async setData() {
      const startTime = dayjs.utc(this.eventData.start_date).format('YYYY-MM-DD HH:mm');
      const endTime = dayjs.utc(this.eventData.end_date).format('YYYY-MM-DD HH:mm');
      this.dataStartDate = startTime;
      this.dataEndDate = endTime;
      this.dataDescription = this.eventData.description;
      this.dataLocation = this.eventData.location;
      this.dataMCode = this.eventData.mcode;
      this.dataLogo = this.eventData.logo;
      this.dataCode = this.eventData.code;
      this.dataName = this.eventData.name;
      this.dataLanguage = this.eventData.language;
      this.dataTimezone = this.eventData.timezone;
      this.dataBaseColor = this.eventData.base_color;
      this.dataIsOnlyParticipant = this.eventData.is_only_participants;
      this.dataEventHtml = this.eventData.custom_html_url;
      this.dataEventHtmlName = this.eventData.custom_html_url;

      // init min date to previous data
      const formattedDate = dayjs(this.dataStartDate).format('YYYY-MM-DDTHH:mm:ss');
      const isAfterCurrentDate = dayjs(formattedDate).isAfter(dayjs());
      if (!isAfterCurrentDate) {
        this.configStartDateTimePicker.minDate = this.dataStartDate;
      } else {
        this.configStartDateTimePicker.minDate = dayjs().format('YYYY-MM-DDTHH:mm:ss');
      }

      const userSettings = JSON.parse(this.user.settings);
      if (userSettings && userSettings.timezone && !this.dataTimezone) {
        this.dataTimezone = userSettings.timezone;
        this.dataLanguage = userSettings.language;
        this.updateEvent();
      }

      this.isFetching = false;
      this.$vs.loading.close();
    },
    updateCurrImgDisplay(input) {
      this.$vs.loading();
      if (input.target.files && input.target.files[0]) {
        this.isUploading = true;
        const file = input.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.dataBackground = e.target.result;
        };
        reader.readAsDataURL(file);

        // Upload Image
        const formData = new FormData();
        formData.append('file', file);

        const callback = (response) => {
          const url = response.data;
          this.dataBackground = url;
          this.isUploading = false;
          this.$vs.loading.close();
        };

        const errorCallback = (e) => {
          const message = getAxiosErrorMessage(e);
          this.$vs.notify({
            title: this.$t('Upload Image'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
          this.isUploading = false;
          this.$vs.loading.close();
        };
        fileApi.upload(formData, callback, errorCallback);
      }
    },
    setEventSettings(eventSettings, code) {
      this.customUrl = code;
      if (eventSettings) {
        // audience qna
        this.dataIsAudienceAndQA = eventSettings.question_answer && eventSettings.question_answer
          .is_enabled ? eventSettings.question_answer.is_enabled : false;
        this.dataIsModeration = eventSettings.question_answer && eventSettings.question_answer
          .is_moderated ? eventSettings.question_answer.is_moderated : false;
        this.dataIsWithdrawal = eventSettings.question_answer && eventSettings.question_answer
          .is_withdrawal ? eventSettings.question_answer.is_withdrawal : false;
        this.dataIsReplies = eventSettings.question_answer && eventSettings.question_answer
          .is_replies ? eventSettings.question_answer.is_replies : false;
        this.dataMaxLength = eventSettings.question_answer && eventSettings.question_answer
          .input_max_length ? eventSettings.question_answer.input_max_length : false;
        this.dataIsDisplayLatestQuestion = eventSettings.question_answer && eventSettings
          .question_answer.is_order_by_latest ? eventSettings.question_answer.is_order_by_latest
          : false;
        this.dataIsCloseQuestion = eventSettings.question_answer && eventSettings.question_answer
          .is_closed ? eventSettings.question_answer.is_closed : false;
        this.dataDisplaySort = eventSettings.question_answer && eventSettings.question_answer.display_sort ? eventSettings.question_answer.display_sort : 'recent';
        this.dataIsShowOnlyRecent = eventSettings.question_answer && eventSettings.question_answer.is_only_recent ? eventSettings.question_answer.is_only_recent : false;
        this.dataIsShowOnlyRecent = eventSettings.question_answer && eventSettings.question_answer.is_only_recent ? eventSettings.question_answer.is_only_recent : false;
        this.dataIsLimitQuestion = eventSettings.question_answer && eventSettings.question_answer.is_limit ? eventSettings.question_answer.is_limit : false;
        this.dataLimitQuestionLength = eventSettings.question_answer && eventSettings.question_answer.limit ? eventSettings.question_answer.limit : 0;
        // sedebar
        this.dataIsQRCode = eventSettings.sidebar && eventSettings.sidebar.is_qr_code
          ? eventSettings.sidebar.is_qr_code : false;
        this.dataIsDisplayPresentSidebar = eventSettings.sidebar && eventSettings.sidebar
          .is_display_presentation_sidebar ? eventSettings.sidebar
            .is_display_presentation_sidebar : false;
        this.dataIsDisplayQuestionPane = eventSettings.sidebar && eventSettings.sidebar
          .is_display_question_pane ? eventSettings.sidebar
            .is_display_question_pane : false;
        this.dataIsRegistrationOnly = eventSettings.sidebar && eventSettings.sidebar
          .is_registration_only ? eventSettings.sidebar
            .is_registration_only : false;
        this.dataIsEnabledRegistrationForm = eventSettings.sidebar && eventSettings.sidebar
          .is_registration_enabled ? eventSettings.sidebar
            .is_registration_enabled : true;
        this.dataIsEnabledExtendedForm = eventSettings.sidebar && eventSettings.sidebar
          .is_enable_extended_field ? eventSettings.sidebar
            .is_enable_extended_field : false;

        // polls
        this.dataIsLivePolls = eventSettings.polls && eventSettings.polls.is_enabled
          ? eventSettings.polls.is_enabled : false;
        this.dataIsVoteCounter = eventSettings.polls && eventSettings.polls
          .is_vote_counter_visible ? eventSettings.polls.is_vote_counter_visible : false;
        this.dataIsPollResult = eventSettings.polls && eventSettings.polls.is_show_result
          ? eventSettings.polls.is_show_result : false;

        // background
        this.dataIsEnabledBoxBackground = eventSettings.style && eventSettings.style.is_enabled_box_bg ? eventSettings.style.is_enabled_box_bg	 : false;
        this.dataIsFullscreen = eventSettings.style && eventSettings.style.is_fullscreen ? eventSettings.style.is_fullscreen	 : false;
        this.dataRemoveFontStyle = eventSettings.style && eventSettings.style.is_remove_font_style ? eventSettings.style.is_remove_font_style	 : false;
        this.dataIsEnabledDarkFont = eventSettings.style && eventSettings.style.is_dark_color_event ? eventSettings.style.is_dark_color_event	 : false;
        this.dataBackgroundType = eventSettings.style && eventSettings.style.background_type ? eventSettings.style.background_type : 'stretch';
        this.dataFontSize = eventSettings.style && eventSettings.style.font_size ? eventSettings.style.font_size : 28;
        this.dataIsEnabledCountdown = eventSettings.style && eventSettings.style.is_enabled_countdown ? eventSettings.style.is_enabled_countdown	 : false;
        this.dataIsHideParticipantName = eventSettings.style && eventSettings.style.is_hide_participant_name ? eventSettings.style.is_hide_participant_name	 : false;
        this.dataIsDarkColor = eventSettings.style && eventSettings.style.is_dark_color ? eventSettings.style.is_dark_color	 : false;
        this.dataDisplayFont = eventSettings.style && eventSettings.style.display_font ? eventSettings.style.display_font	 : 'Roboto';
        this.dataBackground = eventSettings.style && eventSettings.style.background ? eventSettings.style.background	 : null;
        this.dataEventImage = eventSettings.style && eventSettings.style.event_image ? eventSettings.style.event_image	 : null;
        this.dataTC = eventSettings.style && eventSettings.style.tc ? eventSettings.style.tc	: '';
        this.dataIsShowNotes = eventSettings.style && eventSettings.style.is_show_notes ? eventSettings.style.is_show_notes	 : false;
        this.dataNotes = eventSettings.style && eventSettings.style.note ? eventSettings.style.note	 : '';
        this.dataIsShowNotesPlaceholder = eventSettings.style && eventSettings.style.is_show_notes_placeholder ? eventSettings.style.is_show_notes_placeholder	 : false;
        this.dataNotesPlaceholder = eventSettings.style && eventSettings.style.notes_placeholder ? eventSettings.style.notes_placeholder	 : '';

        if (eventSettings && !eventSettings.style) {
          this.dataIsShowCountdown = true;
          this.dataIsShowLocation = true;
          this.dataIsShowDateTime = true;
          this.dataIsShowTC = true;
          this.dataIsShowSwitchQuestionMenu = true;
        } else {
          this.dataIsShowDateTime = eventSettings.style && eventSettings.style.is_show_date_time ? eventSettings.style.is_show_date_time	 : false;
          this.dataIsShowLocation = eventSettings.style && eventSettings.style.is_show_location ? eventSettings.style.is_show_location	 : false;
          this.dataIsShowCountdown = eventSettings.style && eventSettings.style.is_show_countdown ? eventSettings.style.is_show_countdown	 : false;
          this.dataIsShowTC = eventSettings.style && eventSettings.style.is_show_tc ? eventSettings.style.is_show_tc	 : false;
          this.dataIsShowSwitchQuestionMenu = eventSettings.style && eventSettings.style.is_show_switch_question ? eventSettings.style.is_show_switch_question	 : false;
        }

        // presentation & grafias
        this.dataHighlightedGrafiasQuestion = eventSettings.grafias && eventSettings.grafias.highlighted_question ? eventSettings.grafias.highlighted_question : this.highlightGrafiasURL;
        this.dataDisplayRunnerControl = eventSettings.presentation && eventSettings.presentation.display_runner_control ? eventSettings.presentation.display_runner_control	 : this.runnerControlURL;
        this.dataEventQR = eventSettings.presentation && eventSettings.presentation.event_qr ? eventSettings.presentation.event_qr	 : this.eventQRURL;
        this.dataHighlightedPresentQuestion = eventSettings.presentation && eventSettings.presentation.highlighted_question ? eventSettings.presentation.highlighted_question	 : this.highlightPresentationURL;
        this.dataQuestionWithoutSidebar = eventSettings.presentation && eventSettings.presentation.question_without_sidebar ? eventSettings.presentation.question_without_sidebar	 : this.withoutSidebarURL;
        this.dataQuestionWithSidebar = eventSettings.presentation && eventSettings.presentation.question_with_sidebar ? eventSettings.presentation.question_with_sidebar	 : this.sidebarURL;
        this.dataIsEnabledCustomHTML = eventSettings.presentation && eventSettings.presentation.is_enabled_custom_html ? eventSettings.presentation.is_enabled_custom_html	 : false;
        this.dataHighlightGrafiasSwitch = eventSettings.grafias && eventSettings.grafias.is_switch ? eventSettings.grafias.is_switch : this.highlighSwitch;

        // mail
        this.dataSenderName = eventSettings.email && eventSettings.email.sender_name ? eventSettings.email.sender_name : '';

        // must be in the most bottom
        if (!Object.hasOwnProperty.call(eventSettings.style, 'is_show_countdown')) this.dataIsShowCountdown = true;
        if (!Object.hasOwnProperty.call(eventSettings.style, 'is_show_location')) this.dataIsShowLocation = true;
        if (!Object.hasOwnProperty.call(eventSettings.style, 'is_show_date_time')) this.dataIsShowDateTime = true;
        if (!Object.hasOwnProperty.call(eventSettings.style, 'is_show_tc')) this.dataIsShowTC = true;
        if (!Object.hasOwnProperty.call(eventSettings.style, 'is_show_switch_question')) this.dataIsShowSwitchQuestionMenu = true;
      }
    },
    onCopy() {
      this.$vs.notify({
        text: 'Copied to clipboard',
        color: 'success',
        iconPack: 'feather',
        position: 'bottom-right',
        icon: 'icon-check-circle',
      });
    },
    onError() {
      this.$vs.notify({
        title: 'Failed',
        text: 'Error in copying',
        color: 'danger',
        iconPack: 'feather',
        position: 'bottom-right',
        icon: 'icon-alert-circle',
      });
    },
  },
  created() {
    this.fetchDetails();
  },
};

</script>

<style scss lang="scss">
  .card-event {
    width: 100%;
  }

  @media screen and (min-width: 800px) {
    .card-event {
      width: 720px;
    }
  }

  .thumbnail-event {
    min-height: 473px;
    max-height: 473px;
    padding: 2em;
    padding-top: 4.5em;

    .initial-event {
      text-align: center;
      font-size: 7.5em;
      padding: 24px;
      font-weight: bold;
      max-width: 210px;
      margin: 0 auto;
      border-radius: 50%;
      background-color: #00a1ed;
      color: #fff;
    }
  }

  .avatar-col {
    width: 12rem;
  }

  @media screen and (min-width: 1201px) and (max-width: 1211px),
  only screen and (min-width: 636px) and (max-width: 991px) {
    #account-info-col-1 {
      width: calc(100% - 12rem) !important;
    }
  }

  .input-code .vs-con-input .vs-inputx {
    height: 50px !important;
    font-size: 22px !important;
    background-color: #ECECEC;
    border: 0px !important;
    color: black;
  }

  .date-event {
    width: 100%;
    padding: 10px;
    padding: .7rem;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .2);

    &.active {
      border: 1px solid #0154C7;
    }
  }

  .icon-calendar-event {
    color: grey;
    position: absolute;
    top: 0;
    margin: 0.3em;
    right: 0;
  }

  .editor-overview .ql-editor {
    min-height: 400px !important;
  }

  .thumbnail-event {
    min-height: 473px;
    max-height: 473px;
    padding: 2em;
    padding-top: 4.5em;

    .initial-event {
      text-align: center;
      font-size: 7.5em;
      padding: 24px;
      font-weight: bold;
      max-width: 210px;
      margin: 0 auto;
      border-radius: 50%;
      background-color: #00a1ed;
      color: #fff;
    }
  }

  .base-color {
    &:hover {
      outline: 2px solid slategrey;
    }
  }

</style>
